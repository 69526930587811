import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/layout';
import SEO from '@components/seo';
import ContactUs from '@components/ContactUs';
import DefaultImage from '@components/DefaultImage';
import SelectCategory from '@components/SelectCategory';

const NoBlogPosts = () => (
  <div className="no-blog-posts">
    <h2>Sorry, no blog posts for the chosen category...</h2>
    <Link to="/blog" className="primButton blog">
      Back to blog
    </Link>
  </div>
);

class BlogsPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NoOfPost: 6,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    var lastScrollY = window.pageYOffset + 1100;

    if (lastScrollY > window.outerHeight) {
      var count = this.state.NoOfPost + 3;
      this.setState({
        NoOfPost: count,
      });
    }
  };

  render() {
    const { data } = this.props;
    const location = data.location;
    const { NoOfPost } = this.state;

    return (
      <React.Fragment>
        <ul className="blog-list" onScroll={this.onScrollEvent}>
          <SelectCategory
            categories={data.data.allContentfulCategories}
            location={location}
          />
          {data && data.data.contentfulCategories.blogs ? (
            data.data.contentfulCategories.blogs
              .slice(0, NoOfPost)
              .map(item => (
                <li key={item.title}>
                  <div className="post-item template-square columned blog-card">
                    <div className="post-thumbnail">
                      {item && item.featureImage ? (
                        <Img sizes={item.featureImage.fluid} />
                      ) : (
                        <DefaultImage />
                      )}
                    </div>
                    <div className="post-details">
                      <h2 className="post-title">
                        <Link to={`/blog/${item.slug}`}>{item.title}</Link>
                      </h2>
                      <div className="post-date">
                        {/* <i className="fas fa-calendar-alt" /> */}
                        {item.createdAt}
                      </div>
                      <div className="author">
                        {item && item.author.photo ? (
                          <Img sizes={item.author.photo.fluid} />
                        ) : (
                          <DefaultImage />
                        )}
                        <strong className="name">{item.author.name}</strong>
                      </div>
                      <p>{item.description.childMarkdownRemark.excerpt}</p>
                      <div className="read-more-positioning">
                        <Link
                          className="primButton blog"
                          to={`/blog/${item.slug}`}
                        >
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))
          ) : (
            <NoBlogPosts />
          )}
        </ul>
      </React.Fragment>
    );
  }
}

const Categories = data => (
  <Layout>
    <SEO
      title={data.data.contentfulCategories.name}
      keywords={[`gatsby`, `application`, `react`]}
    />
    <div className="container blog-page">
      <BlogsPost data={data} />
    </div>
    <ContactUs
      heading="Don't be a stranger!"
      subTitle="Get in touch!"
      mail="user@domain.com"
      subject="Hello%20User"
    />
  </Layout>
);

export default Categories;

export const query = graphql`
  query CategoriesQuery($slug: String!) {
    allContentfulCategories {
      edges {
        node {
          name
          slug
          blogs {
            id
          }
        }
      }
    }
    contentfulCategories(slug: { eq: $slug }) {
      name
      blogs {
        id
        title
        slug
        categories {
          name
          slug
        }
        createdAt(formatString: "D MMMM YYYY")
        author {
          name
          photo {
            fluid(maxWidth: 350) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        description {
          childMarkdownRemark {
            excerpt(pruneLength: 250)
          }
        }
        featureImage {
          fluid(maxWidth: 1120) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`;
