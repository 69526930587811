import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const DefaultImage = () => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        file(relativePath: { eq: "default-image.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img sizes={data.file.childImageSharp.fluid} />}
  />
);

export default DefaultImage;
