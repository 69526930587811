import React from 'react';

const ContactUs = ({ heading, subTitle, mail, subject }) => (
  <div className="contactus">
    <h2>{heading}</h2>
    <div className="mailto-button">
      <a className="primButton blog" href={`mailto:${mail}?Subject=${subject}`}>
        {subTitle}
      </a>
    </div>
  </div>
);

export default ContactUs;
