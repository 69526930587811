import React from 'react';
import { navigate } from 'gatsby';

class SelectCategory extends React.Component {
  state = {
    value: '',
  };

  componentDidMount = () => {
    if (!this.props.location.state) {
      this.props.location.state = {};
      this.props.location.state.activeCategory = '';
    } else {
      this.setState({
        value: this.props.location.state.activeCategory,
      });
    }
  };

  onChange = event => {
    const activeCategory = event.target.value;
    this.setState({ value: activeCategory });

    if (activeCategory === 'blog') {
      navigate(`${activeCategory}`, {
        state: { activeCategory },
      });
    } else {
      navigate(`/blog/category/${activeCategory}`, {
        state: { activeCategory },
      });
    }
  };

  render() {
    const { categories } = this.props;

    return (
      <div className="blog-categories">
        <select value={this.state.value} onChange={this.onChange}>
          <option value="blog">All categories</option>
          {categories.edges.map(category =>
            category && category.node.blogs ? (
              <option key={category.node.slug} value={category.node.slug}>
                {category.node.name}
              </option>
            ) : null
          )}
        </select>
      </div>
    );
  }
}

export default SelectCategory;
